import React, { useState, useEffect } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import DocumentUploadDialog from "../DocumentUploadDialog/DocumentUploadDialog";
import { FileStatus } from "../DocumentUploadDialog/DocumentUploadDialog";
import DocumentLoadingIcon from "../HeaderButtons/DocumentLoadingIcon";

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  isSendButtonDisabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId: string;
  onRefetchConversation?: () => void;
}

export const QuestionInput = ({
  onSend,
  disabled,
  isSendButtonDisabled,
  placeholder,
  clearOnSend,
  conversationId,
  onRefetchConversation
}: Props) => {
  const [question, setQuestion] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setFileStatuses([]);
    setIsUploading(false);
    setIsDialogOpen(false);
  }, [conversationId]);

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuestion(newValue || "");
  };

  const handleUploadProgress = (statuses: FileStatus[], uploading: boolean) => {
    setFileStatuses(statuses);
    setIsUploading(uploading);
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const uploadedCount = fileStatuses.filter(
    (status) => status.status === "completed"
  ).length;
  const totalFiles = fileStatuses.length;

  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextField
        style={{ backgroundColor: 'white' }}
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        multiline
        resizable={false}
        borderless
        value={question}
        onChange={(e, newValue) => {
          if (newValue !== undefined) {
            onQuestionChange(e, newValue);
          }
        }}
        onKeyDown={onEnterPress}
      />
      <div className={styles.microphoneAndSendContainer}>
        <div
          className={styles.paperclip}
          onClick={openDialog}
          role="button"
          tabIndex={0}
        >
          {isUploading ? (
            <>
              <DocumentLoadingIcon />
              <span className={styles.uploadCountBubble}>{`${uploadedCount}/${totalFiles}`}</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faPaperclip}
                style={{ width: '24px', height: '23px' }}
              />
              {isUploading && totalFiles > 0 && (
                <span className={styles.uploadCountBubble}>{totalFiles}</span>
              )}
            </>
          )}
        </div>

        {isSendButtonDisabled ? (
          <SendRegular className={styles.SendButtonDisabled} />
        ) : (
          <div
            role="button"
            tabIndex={0}
            aria-label="Ask question button"
            onClick={sendQuestion}
            onKeyDown={(e) =>
              e.key === "Enter" || e.key === " " ? sendQuestion() : null
            }
            className={styles.questionInputSendButtonContainer}
          >
            {disabled ? (
              <SendRegular className={styles.questionInputSendButtonDisabled} />
            ) : (
              <img
                src={Send}
                className={styles.questionInputSendButton}
                alt="Send"
              />
            )}
          </div>
        )}

        <DocumentUploadDialog
          isOpen={isDialogOpen}
          onDismiss={closeDialog}
          conversationId={conversationId}
          onUploadProgress={handleUploadProgress}
          onUploadComplete={onRefetchConversation ?? (() => { })}
        />
      </div>
    </Stack>
  );
};
