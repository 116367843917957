import React from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Stack,
    Text,
    FontIcon
} from "@fluentui/react";
import style from "./KeepChattingWarningDialog.module.css"

interface KeepChattingWarningDialogProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const KeepChattingWarningDialog: React.FC<KeepChattingWarningDialogProps> = ({
    isOpen,
    onConfirm,
    onCancel
}) => {
    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={onCancel}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Warning',
                showCloseButton: true
            }}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } }
            }}
        >
            <Stack tokens={{ childrenGap: 12 }}>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                    <FontIcon
                        iconName="Warning"
                        className={style.WarningIcon}
                    />
                    <Text className={style.WarningText}>
                        Documents are still being uploaded
                    </Text>
                </Stack>
                <Text>
                    Your documents are still being uploaded in the background. The chatbot might not
                    have access to all the information until the upload process is complete.
                </Text>
            </Stack>
            <DialogFooter>
                <PrimaryButton
                    text="Continue"
                    onClick={onConfirm}
                />
                <DefaultButton
                    text="Wait"
                    onClick={onCancel}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default KeepChattingWarningDialog;
