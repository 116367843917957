import { DefaultButton, IButtonProps } from "@fluentui/react";
import styles from "./HistoryButton.module.css";

interface ButtonProps extends IButtonProps {
  onClick: () => void;
  text: string | undefined;
  className?: string;
}

export const HistoryButton: React.FC<ButtonProps> = ({ onClick, text, className }) => {
  return (
    <DefaultButton
      text={text}
      iconProps={{ iconName: "History" }}
      onClick={onClick}
      className={className}
    />
  );
};
