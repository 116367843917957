import { useEffect, useMemo, useState, useRef, forwardRef } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { FontIcon, Stack, Text } from "@fluentui/react";
import styles from "./Answer.module.css";
import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  onSpeak?: any;
  isActive?: boolean;
  index: number;
}

const MyStackComponent = forwardRef<HTMLDivElement, any>((props, ref) => (
  <div {...props} ref={ref} />
));

export const Answer = ({
  answer,
  onCitationClicked,
  index,
}: Props) => {
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;
  const answerContainerRef = useRef<HTMLDivElement>(null);
  const messageBoxId = "message-" + index;
  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);
  const refContainer = useRef<HTMLDivElement>(null);

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
    if (chevronIsExpanded && refContainer.current) {
      refContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chevronIsExpanded, isRefAccordionOpen])

  const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id != null) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${citation.chunk_id}`;
      } else {
        citationFilename = `${citation.filepath} - Part ${citation.chunk_id}`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  return (
    <>
      <MyStackComponent className={styles.answerContainer} id={messageBoxId} ref={answerContainerRef}>
        <Stack.Item grow>
          <ReactMarkdown
            remarkPlugins={[remarkGfm, supersub]}
            children={parsedAnswer.markdownFormatText}
            className={styles.answerText}
          />
        </Stack.Item>
        <Stack horizontal className={styles.answerFooter} verticalAlign="start">
          <Stack.Item className={styles.answerDisclaimerContainer}>
            <span className={`${styles.answerDisclaimer} ${styles.mobileAnswerDisclaimer}`}>AI-generated content may be incorrect</span>
          </Stack.Item>

          {!!parsedAnswer.citations.length && (
            <Stack.Item aria-label="References">
              <Stack style={{ width: "100%" }}>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                  tabIndex={0}
                  onClick={handleChevronClick}
                  role="button"
                  onKeyDown={(e) =>
                    e.key === " " || e.key === "Enter"
                      ? handleChevronClick()
                      : null
                  }
                >
                  <Text className={styles.accordionTitle}>
                    <span>
                      {parsedAnswer.citations.length > 1
                        ? parsedAnswer.citations.length + " references"
                        : "1 reference"}
                    </span>
                  </Text>
                  <FontIcon
                    className={styles.accordionIcon}
                    iconName={
                      chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                    }
                    aria-hidden={false}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
          )}
        </Stack>
        {chevronIsExpanded && (
          <div
            ref={refContainer}
            style={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: "4px",
              maxWidth: "100%",
            }}
          >
            {parsedAnswer.citations.map((citation, idx) => {
              return (
                <span
                  role="button"
                  onKeyDown={(e) =>
                    e.key === " " || e.key === "Enter"
                      ? onCitationClicked(citation)
                      : () => { }
                  }
                  tabIndex={0}
                  title={createCitationFilepath(citation, ++idx)}
                  key={idx}
                  onClick={() => onCitationClicked(citation)}
                  className={styles.citationContainer}
                >
                  <div className={styles.citation} key={idx}>
                    {idx}
                  </div>
                  {createCitationFilepath(citation, idx, true)}
                </span>
              );
            })}
          </div>
        )}
        <Stack.Item>
        </Stack.Item>
      </MyStackComponent>
    </>
  );
};
