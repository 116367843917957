import React, { useState, useMemo, useEffect } from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Stack,
    Text,
    Spinner,
    SpinnerSize,
    FontIcon,
    MessageBar,
    MessageBarType
} from "@fluentui/react";
import KeepChattingWarningDialog from './KeepChattingWarningDialog/KeepChattingWarningDialog';
import style from './DocumentUploadDialog.module.css';

export interface FileStatus {
    file: File;
    status: 'pending' | 'uploading' | 'completed' | 'error';
    error?: string;
}

interface FileValidationError {
    fileName: string;
    type: 'size' | 'extension';
    extension?: string;
}

interface DocumentUploadDialogProps {
    isOpen: boolean;
    onDismiss: () => void;
    conversationId: string;
    onUploadProgress: (fileStatuses: FileStatus[], isUploading: boolean) => void;
    onUploadComplete: () => void;
}

const DocumentUploadDialog: React.FC<DocumentUploadDialogProps> = ({
    isOpen,
    onDismiss,
    conversationId,
    onUploadProgress,
    onUploadComplete
}) => {
    const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
    const [validationErrors, setValidationErrors] = useState<FileValidationError[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const MAX_FILES = 5;
    const MAX_FILE_SIZE_MB = 20;
    const ALLOWED_FILE_TYPE = "application/pdf";

    useEffect(() => {
        setFileStatuses([]);
        setValidationErrors([]);
        setIsUploading(false);
        onUploadProgress([], false);
    }, [conversationId]);

    const uploadState = useMemo(() => {
        if (!fileStatuses.length) return 'initial';
        if (fileStatuses.some(f => f.status === 'uploading')) return 'uploading';
        if (fileStatuses.every(f => f.status === 'completed')) return 'completed';
        return 'pending';
    }, [fileStatuses]);

    const dialogTitle = useMemo(() => {
        switch (uploadState) {
            case 'uploading':
                return 'Uploading document(s)...';
            case 'completed':
                return 'Upload completed';
            case 'pending':
                return 'Selected document(s)';
            default:
                return 'Select document(s)';
        }
    }, [uploadState]);

    const validateFile = (file: File): FileValidationError | null => {
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            return {
                fileName: file.name,
                type: 'size'
            };
        }

        if (file.type !== ALLOWED_FILE_TYPE) {
            const extension = file.name.split('.').pop() || 'unknown';
            return {
                fileName: file.name,
                type: 'extension',
                extension
            };
        }

        return null;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const newErrors: FileValidationError[] = [];
        const validFiles: FileStatus[] = [];

        files.forEach(file => {
            const error = validateFile(file);
            if (error) {
                newErrors.push(error);
            } else {
                validFiles.push({
                    file,
                    status: 'pending' as const
                });
            }
        });

        setValidationErrors(newErrors);
        setFileStatuses(validFiles);
    };

    const uploadSingleFile = async (fileStatus: FileStatus) => {
        const formData = new FormData();
        formData.append('files', fileStatus.file);
        formData.append('conversation_id', conversationId);

        try {
            const response = await fetch('/api/upload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(JSON.stringify(errorData.error));
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleUpload = async () => {
        setValidationErrors([]);
        if (fileStatuses.length === 0) return;
        setIsUploading(true);

        let currentFileStatuses = [...fileStatuses];

        onUploadProgress(currentFileStatuses, true);

        for (let i = 0; i < currentFileStatuses.length; i++) {
            currentFileStatuses = currentFileStatuses.map((status, idx) =>
                idx === i ? { ...status, status: 'uploading' } : status
            );
            setFileStatuses(currentFileStatuses);
            onUploadProgress(currentFileStatuses, true);

            const success = await uploadSingleFile(currentFileStatuses[i]);

            currentFileStatuses = currentFileStatuses.map((status, idx) =>
                idx === i ? { ...status, status: success ? 'completed' : 'error' } : status
            );
            setFileStatuses(currentFileStatuses);
            onUploadProgress(currentFileStatuses, true);
        }

        setIsUploading(false);
        onUploadProgress(currentFileStatuses, false);

        // Check if all files were uploaded successfully
        const allCompleted = currentFileStatuses.every(status => status.status === 'completed');
        if (allCompleted) {
            onUploadComplete();
        }
    };


    const handleCancel = () => {
        if (uploadState === "uploading") {
            handleKeepChatting();
        } else if (uploadState !== "completed") {
            setFileStatuses([]);
        }
        setValidationErrors([]);
        onDismiss();
    };

    const getStatusIcon = (status: FileStatus['status']) => {
        switch (status) {
            case 'uploading':
                return <Spinner size={SpinnerSize.small} className="ml-2" />;
            case 'completed':
                return <FontIcon iconName="CheckMark" className="text-green-600 ml-2" />;
            case 'error':
                return <FontIcon iconName="ErrorBadge" className="text-red-600 ml-2" />;
            default:
                return null;
        }
    };

    const handleKeepChatting = () => {
        setShowWarningDialog(true);
    };

    const handleWarningConfirm = () => {
        setShowWarningDialog(false);
        onDismiss();
    };

    const handleWarningCancel = () => {
        setShowWarningDialog(false);
    };

    const renderFooterButtons = () => {
        switch (uploadState) {
            case 'uploading':
                return (
                    <DefaultButton
                        onClick={handleKeepChatting}
                        text="Keep chatting"
                        className={style.btn}
                    />
                );
            case 'completed':
                return (
                    <>
                        <PrimaryButton
                            onClick={() => {
                                setFileStatuses([]);
                                setValidationErrors([]);
                                onUploadProgress([], false);
                            }}
                            text="Upload more files"
                            className={style.btn}
                        />
                        <DefaultButton
                            onClick={handleCancel}
                            text="Close"
                            className={style.btn}
                        />
                    </>
                );
            case 'pending':
                return (
                    <>
                        <PrimaryButton
                            text="Upload"
                            onClick={handleUpload}
                            className={style.btn}
                        />
                        <DefaultButton
                            onClick={handleCancel}
                            text="Cancel"
                            className={style.btn}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <PrimaryButton
                            text="Select"
                            onClick={() => document.getElementById('file-upload')?.click()}
                            className={style.btn}
                        />
                        <DefaultButton
                            onClick={handleCancel}
                            text="Cancel"
                            className={style.btn}
                        />
                    </>
                );
        }
    };

    const renderValidationErrors = () => {
        return validationErrors.map((error, index) => {
            let message = '';
            if (error.type === 'size') {
                message = `"${error.fileName}" is not selected. File size too large.`;
            } else if (error.type === 'extension') {
                message = `"${error.fileName}" is not selected. Unsupported File extension.`;
            }

            return (
                <MessageBar
                    key={index}
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => {
                        setValidationErrors(prev => prev.filter((_, i) => i !== index));
                    }}
                >
                    {message}
                </MessageBar>
            );
        });
    };

    return (
        <>
            <Dialog
                isBlocking
                hidden={!isOpen}
                onDismiss={handleCancel}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogTitle,
                }}
            >
                <Stack tokens={{ childrenGap: 10 }}>
                    <input
                        type="file"
                        id="file-upload"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        multiple
                        accept={ALLOWED_FILE_TYPE}
                    />
                    {fileStatuses.length > 0 ? (
                        <Stack tokens={{ childrenGap: 8 }}>
                            {fileStatuses.map((fileStatus, index) => (
                                <Stack horizontal verticalAlign="center" key={index}>
                                    <Text>{fileStatus.file.name}</Text>
                                    {getStatusIcon(fileStatus.status)}
                                </Stack>
                            ))}
                        </Stack>
                    ) : (
                        <Text>You can attach up to {MAX_FILES} PDF files, each not exceeding {MAX_FILE_SIZE_MB}MB.</Text>
                    )}
                    {validationErrors.length > 0 && (
                        <Stack tokens={{ childrenGap: 8 }}>
                            {renderValidationErrors()}
                        </Stack>
                    )}
                </Stack>
                <DialogFooter>
                    {renderFooterButtons()}
                </DialogFooter>
            </Dialog>
            <KeepChattingWarningDialog
                isOpen={showWarningDialog}
                onConfirm={handleWarningConfirm}
                onCancel={handleWarningCancel}
            />
        </>
    );
};

export default DocumentUploadDialog;
