import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { UseCaseContext } from "./hooks/UseCaseContext";
import { DEFAULT_USE_CASE } from "./util/constants";
import LoginCallback from "./pages/auth/LoginCallback";

initializeIcons();

export default function App() {
    const [useCase, setUseCase] = useState<string>(DEFAULT_USE_CASE);
    return (
        <UseCaseContext.Provider value={{ useCase, setUseCase }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<Chat variant="corporate-knowledge" />} />
                        <Route path="debug" element={<Chat variant="debug" />} />
                        <Route path="general" element={<Chat variant="general" />} />
                        <Route path="corporate-knowledge" element={<Chat variant="corporate-knowledge" />} />
                        <Route path="login" element={<LoginCallback />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UseCaseContext.Provider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
