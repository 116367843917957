import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleLoginCallback } from '../../services/authService';

const LoginCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                if (!code) {
                    throw new Error('No authorization code received');
                }

                const userId = await handleLoginCallback(code);

                navigate('/', { replace: true });
            } catch (error) {
                console.error('Authentication failed:', error);
                //navigate('/', { replace: true });
            }
        };

        handleAuth();
    },);

    return <div>Authenticating...</div>;
};

export default LoginCallback;
