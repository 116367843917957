import React from 'react';

export interface UseCaseContextType {
    useCase: string;
    setUseCase: (useCase: string) => void;
}

export const UseCaseContext = React.createContext<UseCaseContextType | undefined>(undefined);

export const useUseCaseContext = () => {
    const context = React.useContext(UseCaseContext);
    if (context === undefined) {
        throw new Error('useUseCaseContext must be used within a UseCaseProvider');
    }
    return context;
};
