export const checkAndHandleAuth = async () => {
    try {
        // No token found, get EU Login URL and redirect
        const loginUrlResponse = await fetch('api/oauth/eulogin', {
            credentials: 'include'
        });
        const loginUrl = await loginUrlResponse.text();
        console.log('Redirecting to EU Login:', loginUrl);
        window.location.href = loginUrl;
    } catch (error) {
        console.error('Authentication check failed:', error);
    }
};

export const handleLoginCallback = async (code: string) => {
    try {
        const response = await fetch(`api/login?code=${code}`, {
            method: 'POST',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        const userId = await response.text();
        // Store refresh token in session storage after successful login
        window.location.href = '/';
        return userId;
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
};
