import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./Layout.module.css";
import { Stack } from "@fluentui/react";
import EuCo from "../../assets/EuCo.svg";
import HeaderButtons from "../../components/HeaderButtons/HeaderButtons";

type LayoutProps = {
  children: ReactNode;
  toggleSpinner: boolean;
  onSetShowHistoryPanel: () => void;
  showHistoryBtn: boolean;
  showHistoryPanel: boolean;
  conversationId: string;
  onUseInternetChange: (checked: boolean) => void;
  variant?: 'general' | 'corporate-knowledge' | 'debug';
};

const Layout = ({
  children,
  toggleSpinner,
  conversationId,
  onUseInternetChange,
  variant = 'debug',
  ...props
}: LayoutProps) => {
  const { showHistoryPanel, showHistoryBtn, onSetShowHistoryPanel } = props;

  return (
    <div className={styles.layout}>
      <header className={styles.header} role={"banner"}>
        <div className={styles.headerContainer}>
          <Stack horizontal verticalAlign="center">
            <img
              src={EuCo}
              className={styles.headerIcon}
              aria-hidden="true"
              alt="European Commission logo"
            />
            <Link to="/" className={styles.headerTitleContainer}>
              <h3 className={styles.headerTitle}>GPT-INTPA</h3>
            </Link>
            <HeaderButtons
              showHistoryBtn={showHistoryBtn}
              showHistoryPanel={showHistoryPanel}
              onSetShowHistoryPanel={onSetShowHistoryPanel}
              conversationId={conversationId}
              onUseInternetChange={onUseInternetChange}
              variant={variant}
            />
          </Stack>
        </div>
      </header>
      {children}
    </div>
  );
};

export default Layout;
