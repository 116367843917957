import React, { useState, useEffect } from "react";
import {
  Stack,
  DefaultButton,
  IContextualMenuProps,
  Label,
  Checkbox,
} from "@fluentui/react";
import { HistoryButton } from "../../components/HistoryButton/HistoryButton";
import { useUseCaseContext } from "../../hooks/UseCaseContext";
import { USE_CASE_OPTIONS } from "../../util/constants";
import style from "./HeaderButtons.module.css";

interface HeaderButtonsProps {
  showHistoryBtn: boolean;
  showHistoryPanel: boolean;
  onSetShowHistoryPanel: () => void;
  conversationId: string;
  onUseInternetChange: (checked: boolean) => void;
  variant?: 'general' | 'corporate-knowledge' | 'debug';
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  showHistoryBtn,
  showHistoryPanel,
  onSetShowHistoryPanel,
  conversationId,
  onUseInternetChange,
  variant = 'general',
}) => {
  const { useCase, setUseCase } = useUseCaseContext();
  const [useInternet, setUseInternet] = useState(true);

  useEffect(() => {
    switch (variant) {
      case 'general':
        setUseCase('general_llm');
        break;
      case 'corporate-knowledge':
        setUseCase('action_document');
        break;
      // debug variant keeps existing behavior
    }
  }, [variant, setUseCase]);

  useEffect(() => {
    onUseInternetChange(true);
  }, []);

  const handleInternetChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    const newValue = !!checked;
    setUseInternet(newValue);
    onUseInternetChange(newValue);
  };

  return (
    <Stack horizontal className={style.ButtonsContainer}>
      <>
        <Label htmlFor="internet">Disable Internet:</Label>
        <Checkbox
          checked={!useInternet}
          onChange={(ev, checked) => handleInternetChange(ev, !checked)}
          id="internet"
          className={style.InternetCheckbox}
        />

        {variant === 'debug' && (
          <>
            <Label htmlFor="useCase">Use Case:</Label>
            <DefaultButton
              text={USE_CASE_OPTIONS.find((option) => option.key === useCase)?.text || "General LLM"}
              split
              menuProps={{
                items: USE_CASE_OPTIONS.map((option) => ({
                  key: option.key,
                  text: option.text,
                  onClick: () => setUseCase(option.key as string),
                })),
              }}
              className={style.HeaderButton}
              id="useCase"
            />
          </>
        )}

        <HistoryButton
          onClick={onSetShowHistoryPanel}
          text={`${showHistoryPanel ? "Hide" : "Show"} Chat History`}
          className={style.HeaderButton}
        />
      </>
    </Stack>
  );
};

export default HeaderButtons;
